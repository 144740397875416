.modal {
  width: 100%;
  max-width: 650px;
  margin: auto;
  outline: none;

  &_transaction {
    &_title {
      @include themed() {
        color: t('text-alt');
      }
    }

    &_destination {

      display: flex;
      padding: $md $lg;
      border-radius: $radius;
      align-items: center;
      justify-content: space-between;

      img {
        width: 30px;
        height: 30px;
        margin-right: $sm;
      }

      @include themed() {
        border: solid 1px t('border');
      }
    }

    &_details {
      margin-top: $lg;
      display: flex;
      border-radius: $radius;

      @include themed() {
        border: solid 1px t('border');
      }

      &_column {
        flex: 1;
        padding: $md $lg;
      }
    }
  }

  &_actions {
    position: absolute;
    right: $xlg;
    top: 24px;
    display: flex;

    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 17.5px;
      margin-left: $md;
      cursor: pointer;
      transition: 0.3s;

      @include themed() {
        background: t('background-alt');
        border: solid 1px t('border');
      }

      &:hover {
        opacity: $opacity;
      }
    }
  }

  &_content {
    background: $background;
    border-radius: $radius;
    padding: $xlg;
    height: auto;
    position: relative;

    @include themed() {
      color: t('text');
      background: t('background');
    }

    form {
      margin-top: $lg;
      display: block;

      input {
        margin-bottom: $lg;
      }

      .button {
        margin-right: 0;
      }
    }

    .alert {
      position: relative;
    }
  }

  &_overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    z-index: 3;
    background: $modal-overlay;
  }

  &_title {
    margin: 0 0 $xsm 0;
    font-weight: $demibold;
  }

  &_instructions {
    margin: 0;

    @include themed() {
      color: t('text-alt');
    }
  }

  &_body {
    margin-top: 24px;

    h4 {
      margin-top: $xlg;
      margin-bottom: 0;
    }

    p {
      margin-top: $sm;

      @include themed() {
        color: t('text-alt');
      }
    }

    .button {
      height: 46px;
      line-height: 46px;
      margin-right: $lg;
    }
  }

  &_footer {
    margin-top: $xlg;
  }

  .cell {
    margin-top: $md;

    img {
      width: 30px;
      height: 30px;
      margin-right: $md;
    }
  }

  &_clipboard {
    font-size: $p2;
    margin-top: $xlg;
    height: 46px;
    display: flex;
    align-items: center;
    border-radius: $radius;
    padding: 0 $lg;
    font-weight: $demibold;
    position: relative;
    width: fit-content;
    transition: $transition;
    cursor: pointer;

    span {
      margin-right: $md;
      margin-top: 2px;

      @include themed() {
        color: t('accent');
      }
    }

    &:hover {
      opacity: $opacity;
    }

    .icon {
      @include themed() {
        color: t('accent');
      }
    }

    @include themed() {
      background: t('background-alt');
    }
  }
}

@media (max-width: 600px) {
  .modal {
    &_content {
      padding: $xlg $md;
    }

    &_actions {
      right: $md;
    }
  }
}
