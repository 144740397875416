.chart {
  width: 100%;
  height: 440px;
  padding: 50px 0 $sm;
  border-radius: $radius;
  box-sizing: border-box;
  position: relative;

  @include themed() {
    border: solid 1px t('border');

    &_title {
      position: absolute;
      top: 0;
      margin: 20px $lg 0 $lg;
      font-weight: $demibold;
      text-transform: uppercase;
      font-size: $mini;
      color: t('text-alt');
    }

    &_loading {
      padding: $lg;
    }
  }
}

.tooltip {
  border-radius: $radius;
  padding: $lg;

  @include themed() {
    border: solid 1px t('border');
    background: t('background');
  }

  &_title {
    margin-bottom: $md;
  }

  &_item {
    display: flex;
    align-items: center;
    margin-bottom: $sm;

    &_label {
      margin-right: $xlg;
      display: flex;
      align-items: center;

      span {
        height: 10px;
        width: 10px;
        display: block;
        margin-right: $md;
        border-radius: 5px;
      }

      @include themed() {
        color: t('text-alt');
      }
    }

    &_value {
      font-weight: $demibold;
      display: flex;
      align-items: center;
      flex-basis: auto;
      flex-grow: 1;
      justify-content: flex-end;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.barChart {
  padding: 0 $lg;
  border-radius: $radius;

  @include themed() {
    border: solid 1px t('border');
  }

  h4 {
    margin-bottom: $xlg;
  }

  &_item {
    display: flex;
    align-items: center;
    margin: $xlg 0;

    &_title {
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &_bar {
    width: 100%;
    margin: 0 $md;
    background: $exchange-light;
    border-radius: 12px;

    @include themed() {
      background: t('background-alt');
    }

    &_fill {
      transition: $transition;
      border-radius: 12px;
      height: 12px;

      @include themed() {
        background: t('accent');
      }
    }
  }

  &_value {
    font-weight: $demibold;
    width: 120px;
    justify-content: flex-end;
    flex-basis: auto;
    flex-grow: 1;
    display: flex;
  }
}
