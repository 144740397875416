.home {
  &_section {
    padding: 40px 0;
  }
  &_hero {
    height: 600px;
    display: flex;
    align-items: center;
    max-width: 900px;

    h1 {
      margin-bottom: $sm;
    }

    h3 {
      margin-top: $md;
      margin-bottom: $xlg;
    }

    &_cta {
      margin-top: $xlg;

      .input {
        width: 100%;
        min-width: 500px;
        max-width: 500px;
        margin-right: $lg;
      }

      .button {
        margin-right: $lg;
      }
    }
  }

  &_defi {
    &_logo {
      width: 40px;
      height: 40px;
      margin-right: $lg;
      margin-bottom: $md;
    }
  }
}

@media (max-width: 600px) {
  .home {
    &_hero {
      height: 500px;
      display: flex;
      align-items: center;
      max-width: 900px;
      text-align: left;

      h1 {
        font-size: $h2;
      }

      h3 {
        display: none;
      }

      &_block {
        width: 100%;
      }

      &_cta {
        margin-top: $xlg;

        .input {
          min-width: initial;
          margin-bottom: $xlg;
          width: 100%;
          max-width: 100%;
        }

        .button {
          margin-right: 0;
        }
      }
    }

    .header_brand {
      display: flex;
    }
  }
}
