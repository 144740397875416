.settings_item {
  display: flex;
  align-items: center;
  margin-bottom: $lg;

  &_right {
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    justify-content: flex-end;
  }
}
