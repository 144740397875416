.editAddress {
  flex: 1;
  flex-flow: wrap;
  position: relative;

  &_input {
    flex: 1;
    margin-right: $md;
  }

  &_item {
    padding-left: $md;
    display: flex;
    align-items: center;
    height: 54px;
    transition: $transition;
    border-radius: $radius;
    cursor: pointer;
    flex: 1;
    margin-bottom: $md;

    &_icon {
      display: flex;
      align-items: center;
      flex-basis: auto;
      flex-grow: 1;
      justify-content: flex-end;
    }

    &_left {
      flex: 1;
      display: flex;
      align-items: center;
      transition: $transition;

      &:hover {
        opacity: $opacity;
      }
    }

    &_close {
      height: 100%;
      padding: 0 $md;
      display: flex;
      align-items: center;
      flex-basis: auto;
      justify-content: flex-end;

      &:hover {
        .icon {
          color: $danger;
        }
      }
    }

    &--active {
      padding: 0 $md;

      &:hover {
        opacity: $opacity;
      }
    }

    @include themed() {
      color: t('text');
      border: solid 1px t('border');
    }
  }
}
