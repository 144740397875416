table {
  border-radius: $radius;
  padding: $lg;
  width: 100%;
  position: relative;

  @include themed() {
    border: solid 1px t('border');
  }

  img {
    width: 25px;
    height: 25px;
    margin-right: $md;
  }

  .table_header {
    height: 54px;

    th {
      font-weight: $demibold;
      text-transform: capitalize;
      font-size: $h4;
      position: relative;

      &:last-child {
        text-align: left;
      }

      @include themed() {
        color: t('text');
      }
    }

    &_total {
      margin-left: $lg;
    }
  }

  thead {
    tr {
      height: 40px;

      th {
        text-align: left;
        font-weight: $demibold;
        text-transform: uppercase;
        font-size: $mini;

        &:last-child {
          text-align: right;
        }

        @include themed() {
          color: t('text-alt');
        }
      }
    }
  }

  tbody {
    tr {
      height: 40px;

      td:last-child {
        text-align: right;

        .flex {
          justify-content: flex-end;
        }
      }
    }
  }
}

.table {
  padding: 0;

  tbody {
    tr {
      height: 40px;

      td:last-child {
        text-align: left;

        .flex {
          justify-content: flex-start;
        }
      }
    }
  }

  &_filter {
    flex: 1;
    display: flex;

    .icon {
      margin-left: $md;
      position: absolute;

      @include themed() {
        color: t('text-alt');
      }
    }

    &_input {
      flex: 1;
      max-width: 450px;
      box-sizing: border-box;
      height: 40px;
      border-radius: $radius;
      outline: 0;
      transition: $transition;
      padding: $md $lg;
      font-size: $p;
      font-family: "Avenir Next";
      padding-left: 48px;

      @include themed() {
        background: t('background-alt');
        border: solid 1px t('border');
        color: t('text');
      }

      &::placeholder {
        @include themed() {
          color: t('text-alt');
        }
      }

      &:focus {
        border: solid 1px $main;
      }
    }
  }

  &_options {
    display: flex;
    flex: 1;
    margin-bottom: $lg;

    &_right {
      margin-left: $lg;
      display: flex;
      align-items: center;
      flex-basis: auto;
      justify-content: flex-end;
    }
  }

  &_csv {
    font-weight: $demibold;
    margin-left: $md;
    height: 40px;
    line-height: 40px;
    padding: 0 $lg;
    border-radius: $radius;
    cursor: pointer;
    transition: $transition;

    @include themed() {
      background: t('background-alt');
      border: solid 1px t('border');
    }

    &:hover {
      opacity: $opacity;
    }
  }

  th {
    padding: $lg;

    @include themed() {
      border-bottom: solid 1px t('border');
    }

    &:last-child {
      text-align: left;
    }
  }

  &_row {
    transition: $transition;
    cursor: pointer;

    &:hover {
      @include themed() {
        background: t('background-alt');
      }
    }

    &:last-child {
      td {
        @include themed() {
          border-bottom: 0px;
        }
      }
    }

    td {
      padding: $lg;
      height: 50px;

      @include themed() {
        border-bottom: solid 1px t('border');
      }

      &:last-child {
        text-align: left;
      }
    }

    &:last-child {
      @include themed() {
        border-bottom: solid 1px t('border');
      }
    }
  }

  .cell {
    &_PrimaryText {
      font-weight: $demibold;

      &.incoming {
        color: $success;
      }
    }

    &_SecondaryText {
      font-size: $p2;

      @include themed() {
        color: t('text-alt');
      }
    }

    &_amount {
      &_column {
        .cell_PrimaryText {
          margin: $xsm 0;
        }
      }
    }
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: $lg;
  }

  .default_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    margin-right: $lg;
    border-radius: 16px;
    background: $exchange-light;

    &.incoming {
      background: $danger-light;

      .icon {
        color: $danger;
      }
    }

    &.outgoing {
      background: $success-light;

      .icon {
        color: $success;
      }
    }

    .icon {
      color: $exchange;
    }
  }

  .icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    margin-right: $lg;
    border-radius: 8px;

    &.incoming {
      background: $success;

      .icon {
        color: $background;
      }
    }

    &.outgoing {
      background: $danger;

      .icon {
        color: $background;
      }
    }

    &.exchange {
      background: $exchange;

      .icon {
        color: $background;
      }
    }
  }
}

.pagination {
  border-radius: $radius;
  display: flex;
  align-items: center;

  @include themed() {
    background: t('background');
    border: solid 1px t('border');
    color: t('text');
  }

  &_page {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $md;
    font-size: $mini;
    text-transform: uppercase;
    font-weight: $demibold;

    @include themed() {
      color: t('text-alt');
      border-left: solid 1px t('border');
      border-right: solid 1px t('border');
    }
  }

  button {
    font-family: "Avenir Next";
    font-size: $p;
    font-weight: $demibold;
    outline: none;
    transition: .3s;
    padding: 0 $lg;
    line-height: 40px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: $radius;
    border: none;
    background: transparent;

    @include themed() {
      color: t('text');
    }

    &:disabled {
      opacity: 0.25;
    }
  }
}

@media (max-width: 600px) {
  .table {
    &_options {
      display: block;

      &_right {
        margin-left: 0;
        justify-content: flex-start;
      }
    }

    &_filter_input {
      margin-bottom: $lg;
    }

    &_csv {
      display: none;
    }
  }
}
