body {
  margin: 0;
  font-family: "Avenir Next", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

.banner {
  width: 100%;
  height: 40px;
  background: $main-gradient;
  color: $background;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: $demibold;

  .icon {
    margin-left: $xsm;
    color: $background !important;
  }

  a {
    display: flex;
    align-items: center;
    font-weight: $bold;
    margin-left: $sm;
    text-decoration: underline;
  }
}

.background {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;

  @include themed() {
    background: t('background');
  }
}

.app {
  @include themed() {
    color: t('text');
  }
}

.container {
  width: 100%;
  padding: 0 $lg;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  transition: .3s;
  color: inherit;

  &:hover {
    opacity: $opacity;
  }
}

.icon {
  transition: $transition;

  @include themed() {
    color: t('text');
  }

  &--accent {
    @include themed() {
      color: $success;
    }
  }
}

.button {
  font-family: "Avenir Next";
  font-size: $p;
  font-weight: $demibold;
  border: none;
  border-radius: $radius;
  outline: none;
  transition: .3s;
  padding: 0 $xlg;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
  box-shadow: $shadow;
  white-space: nowrap;

  &-gutter {
    margin-right: $lg;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &-add {
    padding: 0;
    width: 54px;
    background: $main-gradient;
    color: $background;
    font-size: $h4;
  }

  &-main {
    background: $main-gradient;
    color: $background;

    &:hover {
      box-shadow: $shadow-focus;
      transform: translateY(-4px);
      opacity: 1;
    }
  }

  &-alt {
    @include themed() {
      background: t('text');
      color: t('background');
    }

    &:hover {
      box-shadow: $shadow-focus;
      transform: translateY(-4px);
    }
  }

  &-link {
    display: flex;
    margin-top: $xlg;
    height: initial;
    line-height: initial;
    font-weight: $demibold;
    font-size: $p2;
    box-shadow: none;
    padding: 0;
    background: transparent;

    @include themed() {
      color: t('accent');
    }

    .icon {
      margin-right: $xsm;

      @include themed() {
        color: t('accent');
      }
    }

    &:hover {
      opacity: $opacity;
    }
  }
}

.text-link {
  font-weight: $demibold;

  @include themed() {
    color: t('accent');
  }
}

form {
  display: flex;
}

.input {
  box-sizing: border-box;
  height: 54px;
  border-radius: $radius;
  outline: 0;
  transition: $transition;
  padding: $md $lg;
  font-size: $p;
  font-family: "Avenir Next";

  @include themed() {
    background: t('background-alt');
    border: solid 2px t('border');
    color: t('text');
  }

  &::placeholder {
    @include themed() {
      color: t('text-alt');
    }
  }

  &:focus {
    border: solid 2px $main;
  }
}

.accent_color {
  font-size: 48px;
  font-weight: $bold;
  background: $main-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.tLogo {
  position: relative;
  display: flex;
}

.tExtra {
  position: absolute;
  right: -7px;
  top: -7px;
  width: 17.5px;
  height: 17.5px;
}

.row {
  display: flex;
  flex-flow: wrap;
  margin-bottom: $xlg;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: $lg;
  margin-bottom: $xlg;
}

.column {
  flex: 1;
  max-width: 50%;
  margin-right: $lg;

  &--2 {
    flex: 2;
    max-width: initial;
  }

  &:last-child {
    margin-right: 0;
  }
}

/* Dashboard Styles */
.summary {
  text-align: center;
  padding: $xlg;
  border-radius: $radius;

  &--column {
    margin-bottom: $lg;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include themed() {
    background: t('background-alt');
  }

  &_title {
    font-size: $h3;
    font-weight: $demibold;
  }

  &_subtitle {
    font-size: $mini;
    text-transform: uppercase;
    margin-bottom: $sm;
    font-weight: $demibold;

    @include themed() {
      color: t('text-alt');
    }
  }
}

.flex {
  display: flex;
  align-items: center;

  &-end {
    justify-content: flex-end;
  }

  .curve_icon {
    background: $background;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    border-radius: 12.5px;
    margin-right: $md;
  }

  &--right {
    justify-content: flex-end;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: $sm;

  &--green {
    background: $success;
  }

  &--red {
    background: $danger;
  }
}

.green {
  color: $success;
}

.red {
  color: $danger;
}

.footer {
  padding: $xlg 0;

  .container {
    display: flex;
  }

  @include themed() {
    border-top: solid 1px t('border');
    color: t('text-alt');
  }

  &_link {
    display: flex;
    font-size: 14px;
    margin-right: $md;

    .twitter {
      color: #1d9ded;
      margin-right: $sm;
    }

    .discord {
      color: #7289da;
      margin-right: $sm;
    }
  }
}

.wall {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: $lg;
  margin: 40px 0;

  &_item {
    border-radius: $radius;
    padding: $lg;
    margin-bottom: $lg;
    min-width: 200px;
    text-align: center;
    background: red;

    @include themed() {
      border: solid 1px t('border');
      background: t('background-alt');
    }

    img {
      margin: 0 auto $lg auto;
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      box-sizing: border-box;
    }
  }
}

.footnote {
  font-size: $mini;
}

.hidden-desktop {
  display: none !important;
}

@media (max-width: 992px) {
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $lg;
  }
}

@media (max-width: 600px) {
  .footer {
    display: none;
  }

  .hidden-mobile {
    display: none !important;
  }

  .hidden-desktop {
    display: initial !important;
  }

  form {
    display: block;

    .button {
      width: 100%;
      margin-bottom: $lg;

      &-add {
        width: 54px;
      }

      &-gutter {
        justify-content: center;
        margin: 0 0 $lg 0;
      }
    }
  }

  .row {
    display: block;
    margin-left: 0;
    margin-bottom: $lg;
  }

  .grid {
    margin-bottom: $lg;
  }

  .column {
    margin: 0 0 $lg 0;
    max-width: 100%;

    &_grid {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: $lg;
  }

  .summary {
    margin-bottom: 0;

    &--column {
      margin-bottom: $lg;
    }
  }
}
