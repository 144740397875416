.subheader {
  @include themed() {
    border-bottom: solid 1px t('border');
  }
}

.menu {
  display: flex;
  position: relative;

  &_item {
    font-weight: $demibold;
    height: 46px;
    padding: 0 $sm;
    margin-right: $lg;
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: $transition;

    &:hover {
      opacity: $opacity;
    }

    @include themed() {
      color: t('text-alt');
    }

    .icon {
      margin-right: $sm;

      @include themed() {
        color: t('text-alt');
      }
    }

    &--active {
      @include themed() {
        color: t('accent');
        border-bottom: solid 2px t('accent');
      }

      .icon {
        @include themed() {
          color: t('accent');
        }
      }
    }

    &_grant {
      height: 36px;
      padding: 0 $md;
      border-radius: $radius;
      margin-right: 0;

      @include themed() {
        color: t('accent');
      }

      .icon {
        @include themed() {
          color: t('accent');
        }
      }
    }
  }

  &_right {
    display: flex;
    align-items: center;
    flex-basis: auto;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.wrapper-content {
  padding-top: $xlg;

  h1, h2, h3, h4, h5 {
    margin-top: 0;
    margin-bottom: $lg;
  }
}

.page {
  &_header {
    display: flex;
    align-items: center;
    height: 60px;

    h1, h2, h3, h4, h5 {
      margin: 0;
    }

    &_controls {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
      align-items: center;
      margin-left: $lg;

      span {
        font-size: $mini;
        text-transform: uppercase;
        font-weight: $demibold;

        @include themed() {
          color: t('text-alt');
        }
      }

      .switch {
        margin-left: $sm;
      }
    }
  }
}

.dashboard {
  &_list {
    &_false {
      display: none;
    }
  }

  &_grid {
    display: block;

    &_false {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .dashboard {
    &_list {
      &_false {
        display: none;
      }
    }

    &_grid {
      &_false {
        display: block;
      }
    }
  }

  .wrapper-content {
    padding: $lg 0 64px 0;
  }

  .menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    @include themed() {
      background: t('background');
      border-top: solid 1px t('border');
    }

    &_item {
      display: block;
      text-align: center;
      padding: 0;
      flex: 1;
      margin-right: 0;
      height: initial;
      padding: $sm 0;
      font-size: $mini;
      text-transform: uppercase;
      font-weight: $demibold;

      .icon {
        margin: 0 auto $sm auto;
        width: 25px;
        height: 25px;
      }

      &--active {
        @include themed() {
          border-bottom: none;
        }
      }
    }
  }
}
