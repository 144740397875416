@mixin loader-rotate {
  @keyframes loader-rotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@mixin loader02(
  $size: $xlg,
  $color: $main,
  $border-size: $xsm,
  $duration: 1s,
  ) {
  width: $size;
  height: $size;
  border: $border-size solid rgba($color, 0.25);
  border-top-color: $color;
  border-radius: 50%;
  position: relative;
  animation: loader-rotate $duration linear infinite;

  @include loader-rotate;
}

.loading {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  @include themed() {
    background: t('background');
  }

  &_indicator {
    @include loader02;
  }
}
