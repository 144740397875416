.alert {
  background: $main-light;
  color: $main;
  position: absolute;
  margin-top: $xlg;
  padding: $lg;
  border-radius: $radius;
  display: flex;
  align-items: center;

  span {
    width: 12px;
    height: 12px;
    background: $main;
    border-radius: 100%;
    margin-right: $md;
  }
}
