.dropdown {
  position: relative;
  cursor: default;

  &_account {
    margin-left: $xlg;

    &_header {
      display: flex;
      align-items: center;
      max-width: 200px;
      padding: 0 $md;
      height: 40px;
      transition: $transition;
      border-radius: $radius;
      font-weight: $demibold;
      cursor: pointer;

      @include themed() {
        background: t('background-alt');
        color: t('text');
        border: solid 1px t('border');
      }

      .icon {
        margin-left: $sm;
      }

      &:hover {
        opacity: $opacity;
      }
    }

    .dropdown_list {
      padding: 0;
      top: 48px;

      &_title {
        margin: $lg $lg $sm $lg;
        font-size: $mini;
        text-transform: uppercase;
        font-weight: $demibold;

        @include themed() {
          color: t('text-alt');
        }
      }

      &_section {
        padding: $sm 0;

        @include themed() {
          border-top: solid 1px t('border');
        }
      }

      &_link {
        display: flex;
        font-weight: $demibold;
        align-items: center;
        padding: $sm $lg;
        cursor: pointer;
        transition: $transition;

        &:hover {
          opacity: $opacity;
        }

        &.manage {
          .icon_wrapper {
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            margin-right: $md;

            @include themed() {
              background: t('background-alt');
            }
          }
        }

        &.no-hover {
          cursor: default;
          justify-content: flex-end;

          .flex {
            align-items: center;
            flex-basis: auto;
            flex-grow: 1;
            justify-content: flex-end;
          }

          &:hover {
            opacity: 1;
          }
        }

        &_exit {
          cursor: pointer;
          font-size: $p2;
          display: flex;
          align-items: center;
          transition: $transition;
          line-height: 14px;

          @include themed() {
            color: t('text-alt');
          }

          &:hover {
            opacity: 1;
            @include themed() {
              color: $danger;
            }

            .icon {
              @include themed() {
                color: $danger;
              }
            }
          }

          .icon {
            margin-left: $xsm;
            @include themed() {
              color: t('text-alt');
            }
          }
        }
      }

      &_item {
        display: flex;
        align-items: center;
        padding: 0;

        &.wallet {
          cursor: default;

          &:hover {
            @include themed() {
              background: none;
            }
          }
        }


        &_wallet {
          padding: $sm $xlg $sm $lg;
          display: flex;
          align-items: center;
          transition: $transition;
          cursor: pointer;

          &:hover {
            opacity: $opacity;

            @include themed() {
              background: none;
            }
          }
        }

        &_end {
          padding: $sm $lg;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-basis: auto;
          flex-grow: 1;
        }

        &_copy {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 35px;
          height: 35px;
          border-radius: 17.5px;
          cursor: pointer;
          transition: $transition;

          @include themed() {
            background: t('background');
            color: t('text');
            border: solid 1px t('border');
          }

          .flex {
            justify-content: center !important;
          }

          &:hover {
            @include themed() {
              background: t('background-alt');
            }
          }

          &:active {
            @include themed() {
              background: $main;
            }
          }
        }
      }
    }
  }

  &_currency {
    &_header {
      display: flex;
      align-items: center;
      margin-left: $lg;
      max-width: 200px;
      padding: 0 $md;
      height: 40px;
      transition: $transition;
      border-radius: $radius;
      font-weight: $demibold;
      cursor: pointer;

      @include themed() {
        background: t('background-alt');
        color: t('text');
        border: solid 1px t('border');
      }

      .icon {
        margin-left: $sm;
      }

      &:hover {
        opacity: $opacity;
      }
    }

    .dropdown_list {
      left: inherit;
      top: 40px;
      right: 0;
    }
  }

  &_list {
    position: absolute;
    z-index: 99;
    border-radius: $radius;
    left: 0;
    padding: $sm;
    min-width: 200px;

    &_row {
      flex-flow: inherit;
      margin: 0;
    }

    @include themed() {
      color: t('text');
      background: t('background');
      box-shadow: t('shadow');
    }

    &_item {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: $md $lg;
      border-radius: $radius;
      font-weight: $demibold;
      transition: $transition;

      &--disabled {
        font-weight: $medium;

        @include themed() {
          color: t('text-alt');
        }

        &:hover {
          background: transparent !important;
        }
      }

      &:hover {
        opacity: 1;
        @include themed() {
          background: t('background-alt');
        }
      }

      img {
        width: 25px;
        height: 25px;
        margin-right: $sm;

        &.currency_icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    &_empty {
      text-align: center;
      padding: $lg $xlg;

      img {
        width: 150px;
        margin: auto;
      }

      p {
        margin: $xlg 0 0;
        font-size: 18px;
        font-weight: $demibold;
      }
    }
  }
}

.currency_icon {
  width: 20px;
  height: 20px;
  margin-right: $sm;
}

@media (max-width: 600px) {
  .dropdown {
    position: initial;

    &_account {
      margin-left: 0;

      .dropdown_list {
        width: 100%;
        left: 0;
      }
    }

    &_currency {
      .dropdown_list {
        left: 16px;
        width: calc(100% - 48px);
        top: inherit;
      }
    }

    &_list {
      width: calc(100% - 16px);
      left: 0;

      .row {
        display: flex;
      }

      .column {
        margin-bottom: 0;
        flex: 1;
        max-width: 50%;
      }
    }
  }
}
