.card {
  margin-bottom: $lg;
  box-sizing: border-box;
  padding: $lg;
  cursor: pointer;
  transition: $transition;
  border-radius: $radius;

  @include themed() {
    border: solid 1px t('border');
  }

  &:hover {
    opacity: $opacity;
  }

  &_header {
    display: flex;
    align-items: center;

    &_icon {
      width: 40px;
      height: 40px;
      margin-right: $lg;
    }

    &_title {
      font-weight: $demibold;
      font-size: $p;
      margin-right: $xlg;
    }

    &_total {
      display: flex;
      align-items: center;
      flex-basis: auto;
      flex-grow: 1;
      justify-content: flex-end;
      font-size: $h4;
      font-weight: $demibold;
    }
  }

  &_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-right: $lg;
    border-radius: 20px;
    background: $success-light;

    .icon {
      color: $success;
    }

    &.debt {
      background: $danger-light;

      .icon {
        color: $danger;
      }
    }
  }
}
