/* Font-Sizes */
$h1: 36px;
$h2: 30px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$p: 16px;
$p2: 14px;
$mini: 12px;

$black: 700;
$bold: 600;
$demibold: 500;
$medium: 400;
$regular: 300;

/* Colors */
$main: #6900FF;
$main-light: rgba(105, 0, 255, 0.15);
$main-alt: #4736FF;
$text: #002237;
$text-alt: #657795;
$border: #E9F1FF;
$background-alt: #F6F9FC;
$background: #ffffff;
$modal-overlay: rgba(0, 34, 55, 0.75);
$alert-color: #FC6DAB;
$main-gradient: linear-gradient(135deg, $main 0%, $main-alt 100%);
$success: #21C090;
$success-light: rgba(33, 192, 144, 0.15);
$danger: #F1356E;
$danger-light: rgba(241, 53, 110, 0.15);
$exchange: #009FFF;
$exchange-light: rgba(0, 159, 255, 0.15);

$shadow: 0 2px 8px rgba(0, 34, 55, 0.1);
$shadow-focus: 0 2px 8px rgba(0, 34, 55, 0.2);

/* Other */
$radius: 4px;
$opacity: 0.6;
$transition: 0.3s;

/*Spacing*/
$xsm: 4px;
$sm: 8px;
$md: 12px;
$lg: 16px;
$xlg: 32px;

$themes: (
  light: (
    background: $background,
    background-alt: $background-alt,
    text: $text,
    text-alt: $text-alt,
    border: $border,
    accent: $main,
    shadow: $shadow,
  ),
  dark: (
    background: #141A1E,
    background-alt: #21262b,
    text: $background,
    text-alt: #9cabbb,
    border: #2d3237,
    accent: $background,
    shadow: 0px 0px 0px 1px rgba(40, 50, 55, 1),
  ),
);

@mixin themed() {
  @each $theme, $map in $themes {
    .theme--#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
