@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-Heavy.otf) format('truetype');
   font-weight: 700;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-Bold.otf) format('truetype');
   font-weight: 600;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-BoldItalic.otf) format('truetype');
   font-weight: 600;
   font-style: italic;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-Demi.otf) format('truetype');
   font-weight: 500;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-DemiIt.otf) format('truetype');
   font-weight: 500;
   font-style: italic;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-Medium.otf) format('truetype');
   font-weight: 400;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-MediumItalic.otf) format('truetype');
   font-weight: 400;
   font-style: italic;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-Regular.otf) format('truetype');
   font-weight: 300;
}

@font-face {
   font-family: 'Avenir Next';
   src: url(../fonts/AvenirNext/AvenirNextLTPro-It.otf) format('truetype');
   font-weight: 300;
   font-style: italic;
}

h1 {
  font-size: $h1;
  font-weight: $bold;
}

h2 {
  font-size: $h2;
  font-weight: $demibold;
}

h3 {
  font-size: $h3;
  font-weight: $medium;
}

h4 {
  font-size: $h4;
  font-weight: $medium;
}

p {
  font-size: $p;
}
